window.addEventListener('load', () => {
    productTimermob1();
})
window.productTimermob1 = function productTimermob1() {
    setInterval(changeproductsauto, 6000);
}
window.changeproductsauto = function changeproductsauto() {
    var firstBlock = document.getElementById('first7');
    var secondBlock = document.getElementById('second7');
    var thirdBlock = document.getElementById('third7');
    var fourBlock = document.getElementById('four7');
    var fiveBlock = document.getElementById('five7');
    var sixBlock = document.getElementById('six7');
    var sevenBlock = document.getElementById('seven7');
    var eightBlock = document.getElementById('eight7');
    var nineBlock = document.getElementById('nine7');


    var oneBlock = document.getElementById('1E');
    var twoBlock = document.getElementById('2E');
    var threeBlock = document.getElementById('3E');
    var foBlock = document.getElementById('4E');
    var fiBlock = document.getElementById('5E');
    var siBlock = document.getElementById('6E');
    var seBlock = document.getElementById('7E');
    var eiBlock = document.getElementById('8E');
    var niBlock = document.getElementById('9E');

    var firstdiv = document.getElementById("swipeMyNine").firstElementChild;
    var lastdiv = document.getElementById("swipeMyNine").lastElementChild;
    var listachievement = document.getElementById("swipeMyNine"); // Get the <ul> element to insert a new node
    lastdiv.insertAdjacentElement("afterend", firstdiv);
    var currentId = listachievement.children[0].id;

    var isPaused = false;
    if (!window.isPaused) {
        if (currentId === 'first7') {

            firstBlock.style.display = 'block';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';
            sevenBlock.style.display = 'none';
            eightBlock.style.display = 'none';
            nineBlock.style.display = 'none';


            oneBlock.classList.add('dotactivSeven');
            twoBlock.classList.remove('dotactivSeven');
            threeBlock.classList.remove('dotactivSeven');
            foBlock.classList.remove('dotactivSeven');
            fiBlock.classList.remove('dotactivSeven');
            siBlock.classList.remove('dotactivSeven');
            seBlock.classList.remove('dotactivSeven');
            eiBlock.classList.remove('dotactivSeven');
            niBlock.classList.remove('dotactivSeven');

        } else if (currentId === 'second7') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'block';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';
            sevenBlock.style.display = 'none';
            eightBlock.style.display = 'none';
            nineBlock.style.display = 'none';

            oneBlock.classList.remove('dotactivSeven');
            twoBlock.classList.add('dotactivSeven');
            threeBlock.classList.remove('dotactivSeven');
            foBlock.classList.remove('dotactivSeven');
            fiBlock.classList.remove('dotactivSeven');
            siBlock.classList.remove('dotactivSeven');
            seBlock.classList.remove('dotactivSeven');
            eiBlock.classList.remove('dotactivSeven');
            niBlock.classList.remove('dotactivSeven');



        } else if (currentId === 'third7') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'block';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';
            sevenBlock.style.display = 'none';
            eightBlock.style.display = 'none';
            nineBlock.style.display = 'none';

            oneBlock.classList.remove('dotactivSeven');
            twoBlock.classList.remove('dotactivSeven');
            threeBlock.classList.add('dotactivSeven');
            foBlock.classList.remove('dotactivSeven');
            fiBlock.classList.remove('dotactivSeven');
            siBlock.classList.remove('dotactivSeven');
            seBlock.classList.remove('dotactivSeven');
            eiBlock.classList.remove('dotactivSeven');
            niBlock.classList.remove('dotactivSeven');


        } else if (currentId === 'four7') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'block';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';
            sevenBlock.style.display = 'none';
            eightBlock.style.display = 'none';
            nineBlock.style.display = 'none';

            oneBlock.classList.remove('dotactivSeven');
            twoBlock.classList.remove('dotactivSeven');
            threeBlock.classList.remove('dotactivSeven');
            foBlock.classList.add('dotactivSeven');
            fiBlock.classList.remove('dotactivSeven');
            siBlock.classList.remove('dotactivSeven');
            seBlock.classList.remove('dotactivSeven');
            eiBlock.classList.remove('dotactivSeven');
            niBlock.classList.remove('dotactivSeven');


        } else if (currentId === 'five7') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'block';
            sixBlock.style.display = 'none';
            sevenBlock.style.display = 'none';
            eightBlock.style.display = 'none';
            nineBlock.style.display = 'none';

            oneBlock.classList.remove('dotactivSeven');
            twoBlock.classList.remove('dotactivSeven');
            threeBlock.classList.remove('dotactivSeven');
            foBlock.classList.remove('dotactivSeven');
            fiBlock.classList.add('dotactivSeven');
            siBlock.classList.remove('dotactivSeven');
            seBlock.classList.remove('dotactivSeven');
            eiBlock.classList.remove('dotactivSeven');
            niBlock.classList.remove('dotactivSeven');



        } else if (currentId === 'six7') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'block';
            sevenBlock.style.display = 'none';
            eightBlock.style.display = 'none';
            nineBlock.style.display = 'none';

            oneBlock.classList.remove('dotactivSeven');
            twoBlock.classList.remove('dotactivSeven');
            threeBlock.classList.remove('dotactivSeven');
            foBlock.classList.remove('dotactivSeven');
            fiBlock.classList.remove('dotactivSeven');
            siBlock.classList.add('dotactivSeven');
            seBlock.classList.remove('dotactivSeven');
            eiBlock.classList.remove('dotactivSeven');
            niBlock.classList.remove('dotactivSeven');



        } else if (currentId === 'seven7') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';
            sevenBlock.style.display = 'block';
            eightBlock.style.display = 'none';
            nineBlock.style.display = 'none';

            oneBlock.classList.remove('dotactivSeven');
            twoBlock.classList.remove('dotactivSeven');
            threeBlock.classList.remove('dotactivSeven');
            foBlock.classList.remove('dotactivSeven');
            fiBlock.classList.remove('dotactivSeven');
            siBlock.classList.remove('dotactivSeven');
            seBlock.classList.add('dotactivSeven');
            eiBlock.classList.remove('dotactivSeven');
            niBlock.classList.remove('dotactivSeven');



        } else if (currentId === 'eight7') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';
            sevenBlock.style.display = 'none';
            eightBlock.style.display = 'block';
            nineBlock.style.display = 'none';

            oneBlock.classList.remove('dotactivSeven');
            twoBlock.classList.remove('dotactivSeven');
            threeBlock.classList.remove('dotactivSeven');
            foBlock.classList.remove('dotactivSeven');
            fiBlock.classList.remove('dotactivSeven');
            siBlock.classList.remove('dotactivSeven');
            seBlock.classList.remove('dotactivSeven');
            eiBlock.classList.add('dotactivSeven');
            niBlock.classList.remove('dotactivSeven');



        } else if (currentId === 'nine7') {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';
            sixBlock.style.display = 'none';
            sevenBlock.style.display = 'none';
            eightBlock.style.display = 'none';
            nineBlock.style.display = 'block';

            oneBlock.classList.remove('dotactivSeven');
            twoBlock.classList.remove('dotactivSeven');
            threeBlock.classList.remove('dotactivSeven');
            foBlock.classList.remove('dotactivSeven');
            fiBlock.classList.remove('dotactivSeven');
            siBlock.classList.remove('dotactivSeven');
            seBlock.classList.remove('dotactivSeven');
            eiBlock.classList.remove('dotactivSeven');
            niBlock.classList.add('dotactivSeven');



        }
    }

}
// var currentId = "first7";

window.changeproducts = function changeproducts(currentId2) {
    var firstBlock = document.getElementById('first7');
    var secondBlock = document.getElementById('second7');
    var thirdBlock = document.getElementById('third7');
    var fourBlock = document.getElementById('four7');
    var fiveBlock = document.getElementById('five7');
    var sixBlock = document.getElementById('six7');
    var sevenBlock = document.getElementById('seven7');
    var eightBlock = document.getElementById('eight7');
    var nineBlock = document.getElementById('nine7');


    var oneBlock = document.getElementById('1E');
    var twoBlock = document.getElementById('2E');
    var threeBlock = document.getElementById('3E');
    var foBlock = document.getElementById('4E');
    var fiBlock = document.getElementById('5E');
    var siBlock = document.getElementById('6E');
    var seBlock = document.getElementById('7E');
    var eiBlock = document.getElementById('8E');
    var niBlock = document.getElementById('9E');

    var isPaused = false;
    window.isPaused = true;

    setTimeout(() => {
        window.isPaused = false;
    }, 15000);

    if (currentId2 === '1E') {

        firstBlock.style.display = 'block';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'none';
        sixBlock.style.display = 'none';
        sevenBlock.style.display = 'none';
        eightBlock.style.display = 'none';
        nineBlock.style.display = 'none';

        oneBlock.classList.add('dotactivSeven');
        twoBlock.classList.remove('dotactivSeven');
        threeBlock.classList.remove('dotactivSeven');
        foBlock.classList.remove('dotactivSeven');
        fiBlock.classList.remove('dotactivSeven');
        siBlock.classList.remove('dotactivSeven');
        seBlock.classList.remove('dotactivSeven');
        eiBlock.classList.remove('dotactivSeven');
        niBlock.classList.remove('dotactivSeven');

    } else if (currentId2 === '2E') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'block';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'none';
        sixBlock.style.display = 'none';
        sevenBlock.style.display = 'none';
        eightBlock.style.display = 'none';
        nineBlock.style.display = 'none';

        oneBlock.classList.remove('dotactivSeven');
        twoBlock.classList.add('dotactivSeven');
        threeBlock.classList.remove('dotactivSeven');
        foBlock.classList.remove('dotactivSeven');
        fiBlock.classList.remove('dotactivSeven');
        siBlock.classList.remove('dotactivSeven');
        seBlock.classList.remove('dotactivSeven');
        eiBlock.classList.remove('dotactivSeven');
        niBlock.classList.remove('dotactivSeven');



    } else if (currentId2 === '3E') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'block';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'none';
        sixBlock.style.display = 'none';
        sevenBlock.style.display = 'none';
        eightBlock.style.display = 'none';
        nineBlock.style.display = 'none';

        oneBlock.classList.remove('dotactivSeven');
        twoBlock.classList.remove('dotactivSeven');
        threeBlock.classList.add('dotactivSeven');
        foBlock.classList.remove('dotactivSeven');
        fiBlock.classList.remove('dotactivSeven');
        siBlock.classList.remove('dotactivSeven');
        seBlock.classList.remove('dotactivSeven');
        eiBlock.classList.remove('dotactivSeven');
        niBlock.classList.remove('dotactivSeven');


    } else if (currentId2 === '4E') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'block';
        fiveBlock.style.display = 'none';
        sixBlock.style.display = 'none';
        sevenBlock.style.display = 'none';
        eightBlock.style.display = 'none';
        nineBlock.style.display = 'none';

        oneBlock.classList.remove('dotactivSeven');
        twoBlock.classList.remove('dotactivSeven');
        threeBlock.classList.remove('dotactivSeven');
        foBlock.classList.add('dotactivSeven');
        fiBlock.classList.remove('dotactivSeven');
        siBlock.classList.remove('dotactivSeven');
        seBlock.classList.remove('dotactivSeven');

        eiBlock.classList.remove('dotactivSeven');
        niBlock.classList.remove('dotactivSeven');

    } else if (currentId2 === '5E') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'block';
        sixBlock.style.display = 'none';
        sevenBlock.style.display = 'none';
        eightBlock.style.display = 'none';
        nineBlock.style.display = 'none';

        oneBlock.classList.remove('dotactivSeven');
        twoBlock.classList.remove('dotactivSeven');
        threeBlock.classList.remove('dotactivSeven');
        foBlock.classList.remove('dotactivSeven');
        fiBlock.classList.add('dotactivSeven');
        siBlock.classList.remove('dotactivSeven');
        seBlock.classList.remove('dotactivSeven');
        eiBlock.classList.remove('dotactivSeven');
        niBlock.classList.remove('dotactivSeven');



    } else if (currentId2 === '6E') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'none';
        sixBlock.style.display = 'block';
        sevenBlock.style.display = 'none';
        eightBlock.style.display = 'none';
        nineBlock.style.display = 'none';

        oneBlock.classList.remove('dotactivSeven');
        twoBlock.classList.remove('dotactivSeven');
        threeBlock.classList.remove('dotactivSeven');
        foBlock.classList.remove('dotactivSeven');
        fiBlock.classList.remove('dotactivSeven');
        siBlock.classList.add('dotactivSeven');
        seBlock.classList.remove('dotactivSeven');
        eiBlock.classList.remove('dotactivSeven');
        niBlock.classList.remove('dotactivSeven');



    } else if (currentId2 === '7E') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'none';
        sixBlock.style.display = 'none';
        sevenBlock.style.display = 'block';
        eightBlock.style.display = 'none';
        nineBlock.style.display = 'none';

        oneBlock.classList.remove('dotactivSeven');
        twoBlock.classList.remove('dotactivSeven');
        threeBlock.classList.remove('dotactivSeven');
        foBlock.classList.remove('dotactivSeven');
        fiBlock.classList.remove('dotactivSeven');
        siBlock.classList.remove('dotactivSeven');
        seBlock.classList.add('dotactivSeven');
        eiBlock.classList.remove('dotactivSeven');
        niBlock.classList.remove('dotactivSeven');



    } else if (currentId2 === '8E') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'none';
        sixBlock.style.display = 'none';
        sevenBlock.style.display = 'none';
        eightBlock.style.display = 'block';
        nineBlock.style.display = 'none';

        oneBlock.classList.remove('dotactivSeven');
        twoBlock.classList.remove('dotactivSeven');
        threeBlock.classList.remove('dotactivSeven');
        foBlock.classList.remove('dotactivSeven');
        fiBlock.classList.remove('dotactivSeven');
        siBlock.classList.remove('dotactivSeven');
        seBlock.classList.remove('dotactivSeven');
        eiBlock.classList.add('dotactivSeven');
        niBlock.classList.remove('dotactivSeven');



    } else if (currentId2 === '9E') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'none';
        sixBlock.style.display = 'none';
        sevenBlock.style.display = 'none';
        eightBlock.style.display = 'none';
        nineBlock.style.display = 'block';

        oneBlock.classList.remove('dotactivSeven');
        twoBlock.classList.remove('dotactivSeven');
        threeBlock.classList.remove('dotactivSeven');
        foBlock.classList.remove('dotactivSeven');
        fiBlock.classList.remove('dotactivSeven');
        siBlock.classList.remove('dotactivSeven');
        seBlock.classList.remove('dotactivSeven');
        eiBlock.classList.remove('dotactivSeven');
        niBlock.classList.add('dotactivSeven');



    }

}
var mySwipeSeven = document.getElementById('swipeMyNine');
if (mySwipeSeven) {
    var mySwipeeSeven = new Hammer(mySwipeSeven, {
        touchAction: "pan-y"
    });

    mySwipeeSeven.get('pan').set({
        direction: Hammer.DIRECTION_ALL
    });



    var idSeS = '';

    mySwipeeSeven.on("swipeleft swiperight", function (ev) {
        var firstSevenBlock7 = document.getElementById('first7');
        var secondSevenBlock7 = document.getElementById('second7');
        var thirdSevenBlock7 = document.getElementById('third7');
        var fourSevenBlock7 = document.getElementById('four7');
        var fiveSevenBlock7 = document.getElementById('five7');
        var sixSevenBlock7 = document.getElementById('six7');
        var sevenSevenBlock7 = document.getElementById('seven7');
        var eightSevenBlock7 = document.getElementById('eight7');
        var nineSevenBlock7 = document.getElementById('nine7');

        var oneBlockSeven7 = document.getElementById('1E');
        var twoBlockSeven7 = document.getElementById('2E');
        var threeBlockSeven7 = document.getElementById('3E');
        var foBlockSeven7 = document.getElementById('4E');
        var fiBlockSeven7 = document.getElementById('5E');
        var sixBlockSeven7 = document.getElementById('6E');
        var sevenBlockSeven7 = document.getElementById('7E');
        var eightBlockSeven7 = document.getElementById('8E');
        var nineBlockSeven7 = document.getElementById('9E');

        // console.log("HERE :: ",ev)
        console.log("HERE :: ", ev.type)
        element = document.getElementsByClassName("dotactivSeven")[0];
        // element = document.querySelector('[data-type="11"]');
        console.log("My Element :: ", element)
        idSeS = element.id;
        console.log("My iD :: ", idSeS)
        if (ev.type === 'swipeleft') {

            if (idSeS === '1E') {
                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'block';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.add('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');

            } else if (idSeS === '2E') {
                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'block';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.add('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');
            } else if (idSeS === '3E') {
                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'block';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.add('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');
            } else if (idSeS === '4E') {
                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'block';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.add('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');

            } else if (idSeS === '5E') {

                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'block';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.add('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');

            } else if (idSeS === '6E') {

                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'block';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.add('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');

            } else if (idSeS === '7E') {

                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'block';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.add('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');
            } else if (idSeS === '8E') {

                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'block';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.add('dotactivSeven');
            } else if (idSeS === '9E') {

                firstSevenBlock7.style.display = 'block';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.add('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');
            }

        }

        if (ev.type === 'swiperight') {

            if (idSeS === '1E') {
                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'block';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.add('dotactivSeven');

            } else if (idSeS === '2E') {
                firstSevenBlock7.style.display = 'block';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.add('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');

            } else if (idSeS === '3E') {
                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'block';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.add('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');

            } else if (idSeS === '4E') {
                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'block';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.add('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');

            } else if (idSeS === '5E') {

                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'block';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.add('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');
            } else if (idSeS === '6E') {

                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'block';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.add('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');

            } else if (idSeS === '7E') {

                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'block';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.add('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');
            } else if (idSeS === '8E') {

                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'block';
                eightSevenBlock7.style.display = 'none';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.add('dotactivSeven');
                eightBlockSeven7.classList.remove('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');
            } else if (idSeS === '9E') {

                firstSevenBlock7.style.display = 'none';
                secondSevenBlock7.style.display = 'none';
                thirdSevenBlock7.style.display = 'none';
                fourSevenBlock7.style.display = 'none';
                fiveSevenBlock7.style.display = 'none';
                sixSevenBlock7.style.display = 'none';
                sevenSevenBlock7.style.display = 'none';
                eightSevenBlock7.style.display = 'block';
                nineSevenBlock7.style.display = 'none';

                oneBlockSeven7.classList.remove('dotactivSeven');
                twoBlockSeven7.classList.remove('dotactivSeven');
                threeBlockSeven7.classList.remove('dotactivSeven');
                foBlockSeven7.classList.remove('dotactivSeven');
                fiBlockSeven7.classList.remove('dotactivSeven');
                sixBlockSeven7.classList.remove('dotactivSeven');
                sevenBlockSeven7.classList.remove('dotactivSeven');
                eightBlockSeven7.classList.add('dotactivSeven');
                nineBlockSeven7.classList.remove('dotactivSeven');
            }
        }
    });
}